import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        list: [],
        listPagination: {},

        typesList: [],

        item: {},

        receipt: {},

        listFilter: {
            page: null,
            limit: 30,
            client_id: null,
            type: null,
            typography_account_id: null,
            'created_at[from]': null,
            'created_at[to]': null,
            sort: 'new',
            direction: 'desc',
        },

        loadingGetList: false,
        loadingGetTypesList: false,
        loadingGetItem: false,
        loadingGetFiscal: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,

        //@TODO: Need to delete in the future
        loadingStartShift: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_TYPES_LIST(state, list) {
            state.typesList = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_RECEIPT(state, receipt) {
            state.receipt = receipt;
        },

        UPDATE_LIST_FILTER(state, newFilterObj) {
            $fn.forIn(newFilterObj, (val, key) => {
                Vue.set(state.listFilter, key, val);
            });

            Vue.set(state.listFilter, 'page', newFilterObj.page || 1);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
                client_id: null,
                is_cash: null,
                'created_at[from]': null,
                'created_at[to]': null,
                sort: 'new',
                direction: 'desc',
            };
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }, filters) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/accounts/transactions`
                    : `/api/clients`;

                let resp = await $axios.get(path, {
                    params: filters || getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_TYPES_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetTypesList',
                    status: true,
                });

                let path = `api/typographies/${rootGetters.currentTypography.id}/transactions/types`;

                let resp = await $axios.get(path);

                commit('SET_TYPES_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetTypesList',
                    status: false,
                });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = `/api/clients/${id}`;
                if (rootGetters.hasTypographyAccess) {
                    path = `/api/typographies/${rootGetters.currentTypography.id}/accounts/transactions/${id}`;
                }

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        GET_FISCAL_RECEIPT: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetFiscal',
                    status: true,
                });

                let resp = await $axios.post(
                    `api/typographies/${rootGetters.currentTypography.id}/transactions/${id}/getFiscalReceipt`,
                );

                dispatch('GET_LIST');
                commit('SET_RECEIPT', resp);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetFiscal',
                    status: false,
                });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = `/api/clients`;
                if (rootGetters.hasTypographyAccess) {
                    path = `/api/typographies/${rootGetters.currentTypography.id}/transactions`;
                }

                let resp = await $axios.post(path, formData);

                dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = `/api/clients/${id}`;
                if (rootGetters.hasTypographyAccess) {
                    path = `/api/typographies/${rootGetters.currentTypography.id}/transactions/${id}`;
                }

                let resp = await $axios.delete(path);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        EXPORT_LIST: async ({ state, commit, getters, rootGetters, dispatch }, filters) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios
                    .get(`/api/typographies/${rootGetters.currentTypography.id}/accounts/transactions/export`, {
                        params: filters || getters.listActiveFilter,
                    })
                    .then((resp) => {
                        const url = resp.link;
                        const link = document.createElement('a');
                        link.href = url;
                        link.click();

                        URL.revokeObjectURL(link.href);
                    });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
    },
};
