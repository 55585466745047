<template>
    <div class="grid">
        <div
            class="grid__col grid__col--md-12"
            :class="{
                'grid__col--2 grid__col--lg-4': isPageLayout,
                'grid__col--4': !isPageLayout,
            }">
            <div class="form__item">
                <form-img
                    v-model="formData.avatar"
                    name="avatar"
                    :surface="isPageLayout ? 'tint-pale' : 'tint-brighter'"
                    :label="$t('form.ava')" />
            </div>
        </div>

        <div
            class="grid__col grid__col--md-12"
            :class="{
                'grid__col--10 grid__col--lg-8': isPageLayout,
                'grid__col--8': !isPageLayout,
            }">
            <div class="form__block" :class="{ grid: isPageLayout }">
                <div class="form__block" :class="{ 'grid__col grid__col--6 grid__col--def-12': isPageLayout }">
                    <div class="form__item">
                        <form-input
                            v-model="formData.last_name"
                            :label="$t('form.name.last')"
                            rules="required"
                            :surface="isPageLayout ? 'tint-pale' : 'tint-brighter'"
                            name="last_name" />
                    </div>

                    <div class="form__item">
                        <form-input
                            v-model="formData.name"
                            :label="$t('admin.form.name')"
                            rules="required"
                            :surface="isPageLayout ? 'tint-pale' : 'tint-brighter'"
                            name="name" />
                    </div>

                    <div class="form__item">
                        <form-date-picker
                            v-model="formData.birthday"
                            :label="$t('form.birthday')"
                            :surface="isPageLayout ? 'tint-pale' : 'tint-brighter'"
                            name="birthday"
                            :surfaceVariant="isPageLayout ? 'tint-brighter' : 'tint-pale'"
                            :hasDateLimit="true"
                            :singleDatePicker="true"
                            :showDropdowns="true" />
                    </div>

                    <div class="form__item" :class="{ '_mb-0 _mb--def-25': isPageLayout }">
                        <form-input
                            v-model="formData.email"
                            :label="$t('form.email')"
                            rules="required|email"
                            :surface="isPageLayout ? 'tint-pale' : 'tint-brighter'"
                            name="email" />
                    </div>
                </div>

                <div class="form__block" :class="{ 'grid__col grid__col--6 grid__col--def-12': isPageLayout }">
                    <div class="form__item">
                        <form-input-tel
                            v-model="formData.phone"
                            :label="$t('form.phone')"
                            rules="phoneNumber"
                            :surface="isPageLayout ? 'tint-pale' : 'tint-brighter'"
                            name="phone" />
                    </div>

                    <div class="form__item has-alt">
                        <form-input
                            v-model="formData.password"
                            :label="hasEditType ? $t('empl.pass.new') : $t('empl.pass')"
                            id="password"
                            class="_mr-5"
                            :rules="hasEditType ? 'min:8' : 'required|min:8'"
                            :surface="isPageLayout ? 'tint-pale' : 'tint-brighter'"
                            name="password" />

                        <druk-icon-button :type="'outlined'" :icon="'copy'" :isDisabled="!formData.password" @click="onCopyPassword" />
                    </div>

                    <div v-if="isOwner" class="form__item">
                        <form-select
                            v-model="formData.typography"
                            :class="{ 'druk-is-disabled': hasEditType }"
                            :options="typographies"
                            :label="$t('common.typog')"
                            option_label="title"
                            rules="required"
                            :surface="isPageLayout ? 'tint-pale' : 'tint-brighter'"
                            :notEmpty="true"
                            name="typography" />
                    </div>

                    <div class="form__item" :class="{ '_mb-10': hasTypographyAccess, '_mb-0': hasERPAccess }">
                        <form-select
                            v-model="formData.role"
                            :class="{
                                'has-loading': loadingGetRole || loadingGetRoleTemplate,
                                'druk-is-disabled': isOwner || isCurrentUser,
                            }"
                            :options="roleOptions"
                            :label="$t('empl.change.role')"
                            option_label="title"
                            :groupLabel="hasTypographyAccess ? 'title' : null"
                            :groupValues="hasTypographyAccess ? 'options' : null"
                            rules="required"
                            :surface="isPageLayout ? 'tint-pale' : 'tint-brighter'"
                            :notEmpty="true"
                            name="role"
                            @input="$emit('onChangeRole', $event)" />
                    </div>

                    <div v-if="hasTypographyAccess" class="form__item _mb-0">
                        <form-switch
                            v-model="formData.is_auto_assigned"
                            name="is_auto_assigned"
                            :label="$t('employees.item.is_auto_assigned')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'employees-form-save',

        inject: ['$validator'],

        props: {
            hasEditType: Boolean,
            isPageLayout: Boolean,
            isOwner: Boolean,
        },

        computed: {
            ...mapState({
                formData: (state) => state.employees.formData,
                roles: (state) => state.roles.list,
                roleTemplates: (state) => state.roles.templates.list,
                typographies: (state) => state.typographies.list,

                user: (state) => state.auth.user,
                item: (state) => state.employees.item,

                loadingGetRole: (state) => state.roles.loadingGetItem,
                loadingGetRoleTemplate: (state) => state.roles.templates.loadingGetItem,
            }),
            ...mapGetters({
                hasERPAccess: 'hasERPAccess',
                hasTypographyAccess: 'hasTypographyAccess',
            }),

            roleOptions() {
                return this.hasTypographyAccess || this.isOwner ? this.dividedRoles : this.roles;
            },

            dividedRoles() {
                return [
                    {
                        title: this.$t('common.role_templates'),
                        options: this.roleTemplates
                            .map((template) => ({ ...template, is_template: true }))
                            .filter((template) => !this.roles.find((role) => role.role_template_id === template.id)),
                    },
                    {
                        title: this.$t('common.typography_roles'),
                        options: this.roles,
                    },
                ];
            },

            isCurrentUser() {
                return this.user.id === this.item.id;
            },
        },

        methods: {
            onCopyPassword() {
                navigator.clipboard.writeText(this.formData.password);
                this.$noty.success(this.$t('empl.pass.copy'));
            },
        },
    };
</script>

<style lang="scss" scoped></style>
