<template>
    <div class="druk-l-support" :class="{ 'druk-is-block': isBlock }">
        <div
            class="druk-l-support__text"
            :class="{
                'druk-is-success': isSuccess && hasNoIcon,
                'druk-is-warning': isWarning && hasNoIcon,
                'druk-is-error': isError && hasNoIcon,
                'druk-is-inverse': isInverse,
                'druk-is-truncated': isTruncated,
            }"
            :style="{ '--max-width': truncate }">
            <div v-if="!hasNoIcon" class="druk-l-support__icon">
                <druk-icon v-bind="iconConfiguration" />
            </div>

            <slot></slot>
        </div>

        <druk-loader v-if="hasLoader" :color="'outline'" :size="'xs'" :isInline="true" />
    </div>
</template>

<script>
    export default {
        name: 'druk-support',

        props: {
            text: String,
            truncate: String,

            isSuccess: Boolean,
            isWarning: Boolean,
            isError: Boolean,

            isBlock: Boolean,
            isInverse: Boolean,
            hasLoader: Boolean,
            hasNoIcon: Boolean,
        },

        computed: {
            icons() {
                return {
                    'circle-check': this.isSuccess,
                    'triangle-exclamation': this.isWarning,
                    'circle-exclamation': this.isError,
                    'circle-info': this.isDefault,
                };
            },

            colors() {
                return {
                    success: this.isSuccess,
                    warning: this.isWarning,
                    error: this.isError,
                    outline: this.isDefault,
                };
            },

            iconConfiguration() {
                return {
                    name: Object.keys(this.icons).find((key) => this.icons[key]),
                    color: Object.keys(this.colors).find((key) => this.colors[key]),
                    size: 'xs',
                    isInline: true,
                };
            },

            isDefault() {
                return !(this.isSuccess || this.isWarning || this.isError);
            },

            isTruncated() {
                return !!this.truncate;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-l-support {
        display: inline-flex;
        &.druk-is-block {
            display: block;
        }
        &__text {
            display: inline-block;
            word-break: break-word;
            text-transform: none;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.5px;
            font-weight: 500;
            color: var(--druk-on-surface-variant);
            &.druk-is-inverse {
                color: var(--druk-inverse-on-surface-variant);
            }
            &.druk-is-truncated {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: var(--max-width);
            }
        }
        &__icon {
            display: inline-flex;
            vertical-align: text-top;
            margin: -1px 2px -1px -2px;
        }
    }
</style>
