export default {
    namespaced: true,
    state: {
        INACTIVE_FILTERING_KEYS: ['page', 'limit', 'sort', 'direction'],

        ORDER_PAYMENT_ID_CAHS: 1,
        PRODUCT_STATUS_ID_NEW: 1,

        TYPOGRAPHY_CLASSIC_COLOR: '#F80416',

        ORDER_STATUS_WAITING: 1,
        ORDER_STATUS_ACCEPTED: 2,
        ORDER_STATUS_CANCELED: 3,
        ORDER_STATUS_COMPLETED: 4,
        ORDER_STATUS_CALCULATION: 5,
        ORDER_STATUS_READY_TO_DELIVERY: 6,
        ORDER_STATUS_IN_PROGRESS: 7,
        ORDER_STATUS_ACCEPTED_BY_MANAGER: 8,
        ORDER_STATUS_PARTY_DELIVERED: 9,

        PRODUCT_STATUS_WAITING: 1,
        PRODUCT_STATUS_CANCELED: 3,
        PRODUCT_STATUS_COMPLETED: 4,
        PRODUCT_STATUS_NEW_PRINT: 7,
        PRODUCT_STATUS_IN_PROGRESS_PRINT: 8,
        PRODUCT_STATUS_NEW_POSTPRESS: 9,
        PRODUCT_STATUS_IN_PROGRESS_POSTPRESS: 10,
        PRODUCT_STATUS_NEW_LOGISTIC: 11,
        PRODUCT_STATUS_IN_PROGRESS_LOGISTIC: 12,
        PRODUCT_STATUS_READY_TO_DELIVERY: 14,
        PRODUCT_STATUS_ACCEPTED_BY_MANAGER: 15,

        TYPOGRAPHY_MODULE_GOOGLE_ANALYTICS: 'google-analytics',
        TYPOGRAPHY_MODULE_BITRIX24: 'bitrix24',
        TYPOGRAPHY_MODULE_LIQPAY: 'liqpay',
        TYPOGRAPHY_MODULE_INSTAGRAM: 'instagram-feed',
        TYPOGRAPHY_MODULE_CHECKBOX: 'checkbox',
        TYPOGRAPHY_MODULE_CUSTOM_CSS: 'custom-css',
        TYPOGRAPHY_MODULE_GTM: 'gtm',
        TYPOGRAPHY_MODULE_BLOG: 'blog',
        TYPOGRAPHY_MODULE_STRIPE: 'stripe',
        TYPOGRAPHY_MODULE_NOVA_POSHTA: 'novaposhta',
        TYPOGRAPHY_MODULE_DPD_BALTIC: 'dpd-baltic',
        TYPOGRAPHY_MODULE_THIRD_PARTY_AUTH: 'third-party-authorization',
        TYPOGRAPHY_MODULE_FACEBOOK_PIXEL: 'facebook',
        TYPOGRAPHY_MODULE_MULTIPLE_DOMAINS: 'multi-domain',
        TYPOGRAPHY_MODULE_ROLL_PRINT: 'roll-print',
        TYPOGRAPHY_MODULE_FLEXO_PRINT: 'flexo-print',

        MOCKUP_DELETING_PAYLOAD: {
            destination_original: [
                'destination_original',
                'preview_original',
                'destination',
                'preview',
                'destination_imposition',
                'preview_imposition',
            ],

            destination_cut_original: [
                'destination_cut_original',
                'preview_cut_original',
                'destination_cut',
                'preview_cut',
                'destination_imposition_cut',
                'preview_imposition_cut',
            ],

            destination: ['destination', 'preview', 'destination_imposition', 'preview_imposition'],

            destination_cut: ['destination_cut', 'preview_cut', 'destination_imposition_cut', 'preview_imposition_cut'],

            destination_imposition: ['destination_imposition', 'preview_imposition'],

            destination_imposition_cut: ['destination_imposition_cut', 'preview_imposition_cut'],

            destination_custom: ['destination_custom'],
        },

        ROTATION_OPTIONS: [0, 90, 180, 270],

        WINDING_OPTIONS: {
            0: {
                outside: 1,
                inside: 5,
            },
            90: {
                outside: 3,
                inside: 7,
            },
            180: {
                outside: 2,
                inside: 6,
            },
            270: {
                outside: 4,
                inside: 8,
            },
        },

        CALCULATION_ROTATION_OPTIONS: [
            {
                title: '0°',
                value: 0,
            },
            {
                title: '90°',
                value: 90,
            },
            {
                title: '180°',
                value: 180,
            },
            {
                title: '270°',
                value: 270,
            },
        ],

        DIRECTION_ASC_KEY: 'asc',
        DIRECTION_DESC_KEY: 'desc',

        MOCKUP_EXTRA_PRICE_KEY: 'mockup_extra_price',

        COMMON_CATEGORY: {
            key: 'createOrder.common_category',
            title: null,
            id: null,
        },

        PRINCIPLE_KEY_OPTIMAL: 'optimal',
        PRINCIPLE_KEY_FASTEST: 'fastest',

        REQUIRE_TRANSLATION_OPTION: 'title_translation_alias',

        TRANSACTION_TYPE_REFUND: 'refund',

        REMAINS_OPTIONS: [
            {
                title: null,
                key: 'common.material_remains.ends',
                value: 'ends',
            },
            {
                title: null,
                key: 'common.material_remains.finished',
                value: 'finished',
            },
            {
                title: null,
                key: 'common.material_remains.sufficiently',
                value: 'sufficiently',
            },
        ],

        PRINCIPLE_OPTIONS: [
            {
                title: null,
                key: 'common.calc.type.cheapest',
                value: 'cheapest',
            },
            {
                title: null,
                key: 'common.calc.type.optimal',
                value: 'optimal',
            },
            {
                title: null,
                key: 'common.calc.type.fastest',
                value: 'fastest',
            },
        ],

        PRINCIPLE_CRITERION: {
            cheapest: 'views.log.time.max',
            optimal: 'views.log.time.opt',
            fastest: 'views.log.time.min',
        },

        LOGS_MAIN_OPERATIONS: {
            price: 'views.log.cost',
            product_kind_price: 'views.log.cost',
            time: 'product.calc.log',
            weight: 'views.log.weight',
            manufacturing_costs: 'orders.product_manufacturing_costs',
        },

        PUBLISHED_OPTIONS: [
            {
                title: null,
                key: 'common.published',
                value: 1,
            },
            {
                title: null,
                key: 'common.hide',
                value: 0,
            },
        ],

        PAID_OPTIONS: [
            {
                title: null,
                key: 'pay.status.true',
                value: 1,
            },
            {
                title: null,
                key: 'pay.status.false',
                value: 0,
            },
        ],

        PRICE_CHANGERS: [
            {
                id: 0,
                key: 'create_order.price_edit.discount',
            },

            {
                id: 1,
                key: 'create_order.price_edit.markup',
            },

            {
                id: 2,
                key: 'create_order.price_edit.fixed_price',
            },
        ],

        PERIOD_OPTIONS: [
            {
                title: null,
                key: 'tasks.modal_export.created_at',
                value: 0,
            },
            {
                title: null,
                key: 'tasks.modal_export.completed_at',
                value: 1,
            },
        ],

        DETAIL_LABELS_DEFAULT: {
            default_padding: 4,
            max_padding: 10,
            default_width: 40,
            max_width: 300,
            default_height: 40,
            max_height: 400,
        },

        ICON_MOCKUP_STATUS_EMPTY: 'fa-regular fa-circle-xmark',
        ICON_MOCKUP_STATUS_SUCCESS: 'fa-regular fa-circle-check',
        ICON_MOCKUP_STATUS_ERROR: 'fa-regular fa-circle-exclamation',

        LABEL_MOCKUP_STATUS_EMPTY: 'orders.item.product_mockups_empty',
        LABEL_MOCKUP_STATUS_SUCCESS: 'orders.item.product_mockups_success',
        LABEL_MOCKUP_STATUS_ERROR: 'orders.item.product_mockups_error',

        OPERATIONS_PERMISSIONS: [
            {
                key: 'front_tasksPre',
                id: 1,
            },
            {
                key: 'front_tasksPrint',
                id: 2,
            },
            {
                key: 'front_tasksPost',
                id: 3,
            },
            {
                key: 'front_tasksLogistic',
                id: 4,
            },
        ],

        CIRCLE_STICKER_TYPE: 0,
        RECTANGLE_ROUNDED_STICKER_TYPE: 2,
        CUSTOM_STICKER_TYPE: 3,

        PARTNERS_PRODUCTS: [
            {
                key: 'sheet_offset_products',
                alias_translation: 'common.partners_products.sheet_offset_products',
                title: null,
                value: 0,
            },
            {
                key: 'pad_printing',
                alias_translation: 'common.partners_products.pad_printing',
                title: null,
                value: 0,
            },
            {
                key: 'sheet_fed_digital_printing',
                alias_translation: 'common.partners_products.sheet_fed_digital_printing',
                title: null,
                value: 0,
            },
            {
                key: 'souvenir_products',
                alias_translation: 'common.partners_products.souvenir_products',
                title: null,
                value: 0,
            },
            {
                key: 'roll_flexo',
                alias_translation: 'common.partners_products.roll_flexo',
                title: null,
                value: 0,
            },
            {
                key: 'label_and_packing',
                alias_translation: 'common.partners_products.label_and_packing',
                title: null,
                value: 0,
            },
            {
                key: 'roll_digital_printing',
                alias_translation: 'common.partners_products.roll_digital_printing',
                title: null,
                value: 0,
            },
            {
                key: 'books_and_magazines',
                alias_translation: 'common.partners_products.books_and_magazines',
                title: null,
                value: 0,
            },
            {
                key: 'large_format_products',
                alias_translation: 'common.partners_products.large_format_products',
                title: null,
                value: 0,
            },
            {
                key: 'fabric_printing',
                alias_translation: 'common.partners_products.fabric_printing',
                title: null,
                value: 0,
            },
            {
                key: 'silkscreen',
                alias_translation: 'common.partners_products.silkscreen',
                title: null,
                value: 0,
            },
            {
                key: 'other',
                alias_translation: 'common.partners_products.other',
                title: null,
                value: 0,
            },
        ],

        PARTNERS_MODULES: [
            {
                key: 'bitrix',
                alias_translation: 'common.partners_modules.bitrix',
                title: null,
                value: 0,
            },
            {
                key: 'roll_print',
                alias_translation: 'common.partners_modules.roll_print',
                title: null,
                value: 0,
            },
            {
                key: 'personal_server',
                alias_translation: 'common.partners_modules.personal_server',
                title: null,
                value: 0,
            },
            {
                key: 'alternative_design',
                alias_translation: 'common.partners_modules.alternative_design',
                title: null,
                value: 0,
            },
            {
                key: 'personal_site',
                alias_translation: 'common.partners_modules.personal_site',
                title: null,
                value: 0,
            },
            {
                key: 'large_format_print',
                alias_translation: 'common.partners_modules.large_format_print',
                title: null,
                value: 0,
            },
            {
                key: 'offset_print',
                alias_translation: 'common.partners_modules.offset_print',
                title: null,
                value: 0,
            },
            {
                key: 'list_labels',
                alias_translation: 'common.partners_modules.list_labels',
                title: null,
                value: 0,
            },
            {
                key: 'prepress_auto',
                alias_translation: 'common.partners_modules.prepress_auto',
                title: null,
                value: 0,
            },
            {
                key: 'contactors',
                alias_translation: 'common.partners_modules.contactors',
                title: null,
                value: 0,
            },
            {
                key: 'stock',
                alias_translation: 'common.partners_modules.stock',
                title: null,
                value: 0,
            },
            {
                key: 'team_management',
                alias_translation: 'common.partners_modules.team_management',
                title: null,
                value: 0,
            },
        ],

        DEFAULT_SORTED_AREA_OPTIONS: [
            {
                title: null,
                key: 'sort.to_new',
                value: 'new_asc',
            },
            {
                title: null,
                key: 'sort.to_old',
                value: 'new_desc',
            },
            {
                title: null,
                key: 'sort.by_alpha',
                value: 'title_asc',
            },
        ],

        CATEGORIES_SORTED_AREA_OPTIONS: [
            {
                title: null,
                key: 'sort.by_default',
                value: 'sort_asc',
            },
        ],

        READY_PRODUCTS_SORTED_AREA_OPTIONS: [
            // {
            // 	title: null,
            // 	key: 'sort.by_increasing_amount',
            // 	value: 'booked_desc',
            // },
            // {
            // 	title: null,
            // 	key: 'sort.by_booking',
            // 	value: 'booked_desc',
            // },
            // {
            // 	title: null,
            // 	key: 'sort.by_increasing_price',
            // 	value: 'price_desc',
            // },
            // {
            // 	title: null,
            // 	key: 'sort.by_lowering_price',
            // 	value: 'price_asc',
            // },
        ],

        SORT_KEY_TITLE: 'title',
        SORT_DIRECTION_ASC: 'asc',

        CHANGER_DISCOUNT: 0,
        CHANGER_MARKUP: 1,
        CHANGER_FIXED_PRICE: 2,

        RELATION_COMMENT_KEY_OPERATION: 'operation',
        RELATION_COMMENT_KEY_PRODUCT: 'orderProduct',

        DEFAULT_SORTING: {
            sort: null,
            direction: null,
        },

        MAX_LENGTH_SEO_TITLE: 60,
        MAX_LENGTH_SEO_DESCRIPTION: 150,

        SHORT_ANIMATION_DURATION: 200,
        MEDIUM_ADNIMATION_DURATION: 300,
        LONG_ANIMATION_DURATION: 650,

        SHORT_ANIMATION_DELAY: 100,
        MEDIUM_ANIMATION_DELAY: 200,
        LONG_ANIMATION_DELAY: 300,
        EXTRA_LONG_ANIMATION_DELAY: 450,

        RIGTH_MOUSE_BUTTON_CODE: 2,
        LEFT_ARROW_KEY: 37,
        TOP_ARROW_KEY: 38,
        RIGHT_ARROW_KEY: 39,
        BOTTOM_ARROW_KEY: 40,
        BACKSPACE_KEY: 8,
        DELETE_KEY: 46,
        MAC_DELETE_KEY: 8,

        LG_SCREEN_WIDTH: 1280,
        MD_SCREEN_WIDTH: 768,
        SM_SCREEN_WIDTH: 480,

        SYSTEM_COLORS_MAP: [
            'azul',
            'water-blue',
            'topaz',
            'dusk',
            'iris',
            'rouge',
            'rose-red',
            'pale-magenta',
            'warm-pink',
            'guardsman-red',
            'scarlet',
            'coral-red',
            'gamboge',
            'sunglow',
            'candlelight',
            'dark-aqua',
            'shamrock-green',
            'atlantis',
            'neutral',
        ],

        SYSTEM_DRUK_COLOR: '#EC2D3C',

        EMPTY_IMG: 'https://druk.s3.amazonaws.com/static/not-images.svg',
    },
};
