import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        ITEM_COMMON_DATA: {
            title: null,
        },

        ITEM_OPTION_COMMON_DATA: {
            text: null,
        },

        list: [],
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
            email: null,
        },

        item: {},

        formData: {},
        optionFormData: {},

        selectedParams: [],

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingSaveOption: false,
        loadingDeleteItem: false,
        loadingGetLogs: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => !!state.listFilter[key])
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_FILTER_LIST(state, { key, val }) {
            state.listFilter[key] = val;
        },

        SET_FORM_DATA(state, formData) {
            state.formData = formData;
        },

        SET_OPTION_FORM_DATA(state, formData) {
            state.optionFormData = formData;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        SET_REQUEST_PARAMS(state, params) {
            let list = [];

            params.forEach((el) => {
                list.push({
                    id: el.param.id,
                    title: el.param.title,
                    select: {
                        id: el.id,
                        text: el.text,
                    },
                    options: [],
                });
            });

            state.selectedParams = list;
        },

        SET_PARAM(state, { param, type }) {
            if (type === 'push') {
                state.selectedParams.push(
                    $fn.assign(param, {
                        select: null,
                    }),
                );
            }
        },

        DELETE_PARAM(state, id) {
            let selectedIndex = $fn.findIndex(state.selectedParams, ['id', id]);
            Vue.delete(state.selectedParams, selectedIndex);
        },

        SET_PARAM_VALUE(state, { param, id }) {
            let selectedIndex = $fn.findIndex(state.selectedParams, ['id', id]);
            Vue.set(state.selectedParams[selectedIndex], 'select', param);
        },

        SET_PARAM_OPTIONS_LIST(state, optionsList) {
            optionsList.forEach((el) => {
                let param = $fn.find(state.selectedParams, ['id', el.id]);
                if (param) {
                    param.options = el.options;
                }
            });
        },

        RESET_STORE(state) {
            state.selectedParams = [];
        },

        RESET_OPTION_FORM_DATA(state) {
            state.optionFormData = {};
        },
    },

    actions: {
        GET_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/materials/params`, {
                    params: getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/materials/params/${id}`);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, { formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/materials/params`, formData);

                if (!without_fetch) {
                    dispatch('GET_LIST');
                    dispatch('materials/GET_LIST', null, { root: true });
                }

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_ITEM_OPTION: async ({ state, rootGetters, commit, dispatch }, { id, formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveOption',
                    status: true,
                });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/materials/params/${id}/options`,
                    formData,
                );

                if (!without_fetch) dispatch('GET_ITEM', id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSaveOption',
                    status: false,
                });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(`/api/typographies/${rootGetters.currentTypography.id}/materials/params/${id}`);

                dispatch('GET_LIST');
                dispatch('materials/GET_LIST', null, { root: true });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
