//utils
import './utils/filters';
import './utils/global-components';

import './utils/fontawesome/index.js';
import './utils/fontawesome/regular.js';
import './utils/fontawesome/solid.js';

import './utils/material';
Vue.prototype.$material = $material;

import './utils/fn';
Vue.prototype.$fn = $fn;

import './utils/deviceDetect';
Vue.prototype.$device = $device;

import './utils/colorFilter';
Vue.prototype.$colorFilter = $colorFilter;

import ChartJSCustomPlugins from './utils/chart-js/index.js';
Vue.prototype.$ChartJSCustomPlugins = ChartJSCustomPlugins;

//plugins
import './utils/axios/index';
import './utils/axios/customNoty';
Vue.prototype.$customNoty = $customNoty;

import i18n from './utils/i18n';

import Vue from './utils/vue_base.js';
import App from './App.vue';
Vue.config.productionTip = false;

import store from './store';
import router from './router';

//--------bus events----------------/
Vue.prototype.$bus = new Vue();

//--------noty--------------------------/
import VueNoty from 'vuejs-noty';
import 'vuejs-noty/dist/vuejs-noty.css';

Vue.use(VueNoty, {
    timeout: 4000,
    theme: 'sunset',
    progressBar: true,
    layout: 'bottomRight',
});

//--------echo---------------------/
import VueEcho from 'vue-echo-laravel';
import Pusher from 'pusher-js';

Vue.use(VueEcho, {
    broadcaster: 'pusher',
    key: 'druk',
    wsHost: process.env.VUE_APP_WS_HOST,
    wsPort: process.env.VUE_APP_WS_PORT,
    disableStats: true,
});

//--------validator---------------------/
import VeeValidate, { Validator } from 'vee-validate';
import PhoneNumber from 'awesome-phonenumber';
import attributesUk from 'vee-validate/dist/locale/uk';
import attributesEn from 'vee-validate/dist/locale/en';
import attributesPl from 'vee-validate/dist/locale/pl';
import attributesRu from 'vee-validate/dist/locale/ru';

Vue.use(VeeValidate);

Validator.extend('alias', {
    getMessage: (field) =>
        'Поле ' + `${field}` + ' може містити тільки літери латинського алфавіту, цифри, знак підкреслення або крапку',
    validate: (value) => {
        const validChars = /[0-9/A-Z/a-z/./_]/,
            invalidChars = /[^0-9/^A-Z/^a-z/^./^_]/;

        let containsValidChars = invalidChars.test(value);
        let containsInvalidChars = validChars.test(value);

        return !containsValidChars && containsInvalidChars ? true : false;
    },
});

Validator.extend('phoneNumber', {
    getMessage: (field) => `Is not a valid phone number`,
    validate(value) {
        return new Promise((resolve) => {
            let phone = new PhoneNumber(value);
            resolve({ valid: phone.isValid() });
        });
    },
});

Validator.extend('alphabets', {
    validate(value, args) {
        let cyrillic = args.includes('cyrillic') ? 'а-яА-ЯӘәҐґҒғЄєЁёҖҗІіЇїҚқҢңҲҳӨөҰұҮүӮӯЎўҺһҲҳ' : '',
            latin = args.includes('latin')
                ? 'a-zA-ZĄąĀāĄąÄäĆćČčÇçĘęĒēĖėĢģĞğƢƣĪīĮįĶķŁłĻļŃńŅņŊŋŇňÑñÓóÖöÕõƟɵÕõŚśŠšŞşŞşŪūŲųÜüÝýŹźŻżŽžƵƶЬь’‘'
                : '',
            georgian = args.includes('georgian') ? 'აბგდევზთიკლმნოპჟრსტუფქღყშჩცძწჭხჯჰჱჲჳჴჵჷჸჶჹჺჽჾჿ' : '',
            dashes = /^[^-]*-?[^-]*$/;

        let valid = new RegExp(String.raw`[${cyrillic}${latin}${georgian}]`, 'g'),
            invalid = new RegExp(String.raw`[^${cyrillic}${latin}${georgian}]`, 'g');

        return valid.test(value) && !invalid.test(value) && dashes.test(value) && value.length > 1;
    },
});

Validator.extend('int', {
    getMessage: (field) => `Поле ${field} може містити тільки цілі числа`,
    validate(value) {
        let valid = /[0-9]/,
            invalid = /[^0-9]/;

        return valid.test(value) && !invalid.test(value);
    },
});

Validator.extend('not_in_array', {
    getMessage: (field, args) => `Поле ${field} не може містити наступні значення: ${args.join(', ')}`,
    validate(value, args) {
        return !args.includes(+value);
    },
});

Validator.extend('even_numbers', {
    getMessage: (field) => `Поле ${field} може містити тільки парні числа`,
    validate(value) {
        return value % 2 === 0;
    },
});

Validator.extend('float', {
    getMessage: (field) => `Поле ${field} може містити тільки цілі числа та десяткові дроби`,
    validate(value) {
        let valid = /^[+-]?\d*(\.|\,)?\d+$/;
        return valid.test(value);
    },
});

Validator.extend('positive_float', {
    getMessage: (field) => `Поле ${field} може містити тільки натуральні числа та додатні десяткові дроби`,
    validate(value) {
        let valid = /^\d+(\.\d+)?$/;
        return valid.test(value);
    },
});

const dictionary = {
    uk: {
        messages: {
            alias: (field) => `Поле ${field} може містити тільки літери латинського алфавіту, цифри, знак підкреслення або крапку`,
            phoneNumber: 'Ви ввели невалідний номер телефону',

            alphabets: (field, args) => {
                return `Поле ${field} може містити тільки літери ${args
                    .map((item) => i18n.t(`typographies.validator_alpabets.${item}_sm_genitive_fem`))
                    .join(', ')
                    .replace(/, ([^,]*)$/, ` та $1`)} абетки(-ок) та одне тире. Мінімальна довжина поля - 2 знаки`;
            },

            int: (field) => `Поле ${field} може містити тільки цілі числа`,
            not_in_array: (field, args) => `Поле "${field}" не може містити наступні значення: ${args.join(', ')}`,
            even_numbers: (field) => `Поле ${field} може містити тільки парні числа`,
            float: (field) => `Поле ${field} може містити тільки цілі числа та десяткові дроби`,
            positive_float: (field) => `Поле ${field} може містити тільки натуральні числа та додатні десяткові дроби`,
        },
    },
    en: {
        messages: {
            alias: (field) => `The alias ${field} can contains only latin letters, numbers, dashes or dots`,
            phoneNumber: 'Is not a valid phone number',

            alphabets: (field, args) => {
                return `The field ${field} can only contain letters of the ${args
                    .map((item) => i18n.t(`typographies.validator_alpabets.${item}_sm_genitive_fem`))
                    .join(', ')
                    .replace(/, ([^,]*)$/, ` and $1`)} alphabet(s) and one dash. The minimum field length is 2 characters`;
            },

            int: (field) => `The field ${field} can only contain integers`,
            not_in_array: (field, args) => `The field "${field}" cannot contain the following values: ${args.join(', ')}`,
            even_numbers: (field) => `The filed ${field} can contain only even numbers`,
            float: (field) => `The filed ${field} can contain only integers and decimal fractions`,
            positive_float: (field) => `The filed ${field} can contain only natural numbers and positive decimal fractions`,
        },
    },
    pl: {
        messages: {
            alias: (field) => `Pole ${field} może zawierać tylko litery alfabetu łacińskiego, cyfry, podkreślenie lub kropkę`,
            phoneNumber: 'Podałeś nieprawidłowy numer telefonu',

            alphabets: (field, args) => {
                return `Pole ${field} może zawierać tylko litery ${args
                    .map((item) => i18n.t(`typographies.validator_alpabets.${item}_sm_genitive_fem`))
                    .join(', ')
                    .replace(/, ([^,]*)$/, ` lub $1`)} alfabety oraz jeden myślnik. Minimalna długość pola to 2 znaki`;
            },

            int: (field) => `Pole ${field} może zawierać tylko liczby całkowite`,
            not_in_array: (field, args) => `Pole "${field}" nie może zawierać następujących wartości: ${args.join(', ')}`,
            even_numbers: (field) => `Pole ${field} może zawierać tylko liczby parzyste`,
            float: (field) => `Pole ${field} może zawierać całkowite liczb naturalnych i ułamków dziesiętnych`,
            positive_float: (field) => `Pole ${field} może zawierać wyłącznie liczb naturalnych i dodatnich ułamków dziesiętnych`,
        },
    },
    ru: {
        messages: {
            alias: (field) => `Поле ${field} может содержать только буквы латинского алфавита, цифры, знак подчёркивания или точки`,
            phoneNumber: 'Вы ввели невалидный номер телефона',

            alphabets: (field, args) => {
                return `Поле ${field} может содержать только буквы ${args
                    .map((item) => i18n.t(`typographies.validator_alpabets.${item}_sm_genitive_fem`))
                    .join(', ')
                    .replace(/, ([^,]*)$/, ` и $1`)} алфавита(-ов) и одно тире. Минимальная длина поля – 2 знака`;
            },

            int: (field) => `Поле ${field} может содержать только целые числа`,
            not_in_array: (field, args) => `Поле "${field}" не может содержать следующие значения: ${args.join(', ')}`,
            even_numbers: (field) => `Поле ${field} может содержать только четные числа`,
            float: (field) => `Поле ${field} может содержать только целые числа и десятичные дроби`,
            positive_float: (field) => `Поле ${field} может содержать только натуральные числа и десятичные дроби`,
        },
    },
};

Validator.localize(dictionary);

Vue.mixin({
    methods: {
        setValidateLocalization(locale) {
            switch (locale) {
                case 'uk':
                    Validator.localize('uk', attributesUk);
                    break;
                case 'pl':
                    Validator.localize('pl', attributesPl);
                    break;
                case 'en':
                    Validator.localize('en', attributesEn);
                    break;
                case 'ru':
                    Validator.localize('ru', attributesRu);
                    break;
            }
        },
    },
});

//-----hotjar---------------------/
import Hotjar from 'vue-hotjar';
Vue.use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_ID || '3194969', //@TODO delete default data
    snippetVersion: 6,
});

//--------modal--------------------------/
import VModal from 'vue-js-modal';
Vue.use(VModal, {
    dynamic: true,
    injectModalsContainer: true,
});

window.$MODAL_OPTIONS = {
    default: {
        delay: 100,
        draggable: false,
        adaptive: true,
        scrollable: true,
        height: 'auto',
        clickToClose: true,
    },
};
$MODAL_OPTIONS.tiny = {
    ...$MODAL_OPTIONS.default,
    width: 445,
    maxWidth: 445,
};
$MODAL_OPTIONS.normal = {
    ...$MODAL_OPTIONS.default,
    width: 700,
    maxWidth: 700,
};
$MODAL_OPTIONS.big = {
    ...$MODAL_OPTIONS.default,
    width: 900,
    maxWidth: 900,
};
$MODAL_OPTIONS.full = {
    ...$MODAL_OPTIONS.default,
    width: 1280,
    maxWidth: 1280,
};
$MODAL_OPTIONS.entire = {
    ...$MODAL_OPTIONS.default,
    width: '100%',
    maxWidth: 1600,
};
$MODAL_OPTIONS.alert = {
    ...$MODAL_OPTIONS.default,
    width: 280,
    maxWidth: 280,
    clickToClose: false,
};

//--------vue-awesome-swiper--------------------------/
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

Vue.use(VueAwesomeSwiper);

//--------vue-slider-component--------------------------/
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/material.css';

Vue.component('vue-slider', VueSlider);

//-------druk-snackbar--------------/
import snackbar from './utils/snackbar';
Vue.use(snackbar);

//--------------------------------------/
let app = null;

let urlParams = new URLSearchParams(window.location.search);

let routeLocale = urlParams.get('lang'),
    verifyError = urlParams.get('verify-error'),
    verifyErrorMessage = urlParams.get('error-message');

Vue.set(store.state, 'VERIFY_ERROR', !!+verifyError);
Vue.set(store.state, 'VERIFY_ERROR_MESSAGE', verifyErrorMessage);

i18n.setLang(routeLocale || i18n.locale).then(() => {
    app = new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App),
    }).$mount('#app');

    window.$VUE_APP = app;
});
