import Vue from 'vue';

import categories from './categories.js';
import articles from './articles.js';
import renewals from './modules/renewals.js';

export default {
    namespaced: true,

    modules: {
        categories,
        articles,
        renewals,
    },

    state: {
        // @Explanation: List of base aliases. You can create new base or delete old by alias with actions inside this store
        BASES_MAP: {
            engine: {
                alias: 'engine',
                title: 'knowledge.engine.title',
            },

            partner: {
                alias: 'partner',
                title: 'knowledge.partner.title',
            },

            renewals: {
                alias: 'renewals',
                title: 'knowledge.renewals.title',
            },
        },

        SEARCH_KEYS: [
            {
                alias: 'categories',
                key: 'knowledge.search_results.categories',
                title: null,
            },

            {
                alias: 'articles',
                key: 'knowledge.search_results.articles',
                title: null,
            },
        ],

        list: [],
        item: {},
        searchResult: {},

        listFilter: {
            title: null,
        },

        loadingGetList: false,
        loadingGetItem: false,
        loadingGetSearchResult: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
        loadingSortNested: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => !!state.listFilter[key])
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_SEARCH_RESULT(state, item) {
            state.searchResult = item;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilters) {
            $fn.forIn(newFilters, (val, key) => {
                Vue.set(state.listFilter, key, val);
            });
        },
    },

    actions: {
        // @Explanation: API-request for developers. Users should not have access to them
        GET_LIST: async ({ commit, rootState, rootGetters, state, getters }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/knowledge`);

                commit('SET_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
        // --@

        GET_ITEM: async ({ commit, rootState, rootGetters, state, getters }, { alias, filters }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/knowledge/${alias}`, {
                    params: filters,
                });

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        GET_SEARCH_RESULT: async ({ commit, rootState, rootGetters, state, getters }, { alias, filters }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetSearchResult',
                    status: true,
                });

                let resp = await $axios.get(`/api/knowledge/${alias}`, {
                    params: Object.assign(
                        {
                            with_categories: 1,
                            with_articles: 1,
                        },
                        filters,
                    ),
                });

                commit('SET_SEARCH_RESULT', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetSearchResult',
                    status: false,
                });
            }
        },

        // @Explanation: API-request for developers. Users should not have access to them
        SAVE_ITEM: async ({ commit, dispatch, rootState, rootGetters, state, getters }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/knowledge`, formData);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },
        // --@

        // @Explanation: API-request for developers. Users should not have access to them
        DELETE_ITEM: async ({ commit, dispatch, rootState, rootGetters, state, getters }, alias) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(`/api/knowledge/${alias}`);

                if (resp.success) dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
        // --@

        SORT_NESTED: async ({ commit, dispatch, rootState, rootGetters, state, getters }, { alias, formData }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSortNested',
                    status: true,
                });

                let resp = await $axios.post(`/api/knowledge/${alias}/sort`, formData);

                dispatch('GET_ITEM', { alias, filters: { with_categories: 1 } });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSortNested',
                    status: false,
                });
            }
        },
    },
};
