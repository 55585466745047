import Vue from 'vue';

import templates from './templates.js';

export default {
    namespaced: true,

    modules: {
        templates,
    },

    state: {
        list: [],
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
            sort: 'new',
            direction: 'desc',
        },

        typographyList: [],
        typographyListPagination: {},
        typographyListFilter: {
            page: null,
            limit: 30,
            sort: 'new',
            direction: 'desc',
        },

        item: {},

        loadingGetList: false,
        loadingGetTypographyList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => !!state.listFilter[key])
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        typographyListActiveFilter(state) {
            return Object.keys(state.typographyList)
                .filter((key) => !!state.typographyList[key])
                .reduce((resp, key) => ((resp[key] = state.typographyList[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_TYPOGRAPHY_LIST(state, list) {
            state.typographyList = list;
        },

        SET_TYPOGRAPHY_LIST_PAGINATION(state, pagination) {
            state.typographyListPagination = pagination;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_ITEM(state) {
            state.item = {};
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
                sort: 'new',
                direction: 'desc',
            };
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, rootGetters, commit }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/roles`
                    : `/api/roles`;

                let resp = await $axios.get(path, {
                    params: Object.keys(filters).length ? filters : getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_TYPOGRAPHY_LIST: async ({ state, getters, rootGetters, commit }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetTypographyList',
                    status: true,
                });

                let path = `/api/roles/typography-roles`;

                let resp = await $axios.get(path, {
                    params: Object.keys(filters).length ? filters : getters.typographyListActiveFilter,
                });

                commit('SET_TYPOGRAPHY_LIST', resp.list);
                commit('SET_TYPOGRAPHY_LIST_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetTypographyList',
                    status: false,
                });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/roles/${id}`
                    : `/api/roles/${id}`;

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/roles`
                    : `/api/roles`;

                let resp = await $axios.post(path, formData);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/roles/${id}`
                    : `/api/roles/${id}`;

                let resp = await $axios.delete(path);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
