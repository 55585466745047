<template>
    <div class="selector selector--file" :class="{ 'has-full': hasFull }">
        <div class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <i v-if="hasRequired">*</i>
            </span>

            <druk-hint
                v-if="hint"
                :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                :icon="{ name: 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main" :class="{ [`druk-l-surface-${surface}`]: surface }">
            <input
                class="selector__area selector__area--file-picker"
                :class="{ 'druk-is-disabled': disabled }"
                type="file"
                ref="file"
                :id="name"
                :name="name"
                @change="processFile($event)"
                :placeholder="placeholder" />

            <div class="selector__mask" :class="{ 'druk-is-placeholder': !file && !value }" @click="processFile()">
                <span>{{ file ? file.name : value || $t('file.choose') }}</span>
            </div>
        </div>

        <div v-if="errors.has(name)" class="selector__error">
            <span :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText || errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-file',
        inject: ['$validator'],
        props: {
            value: {
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            hint: [String, Object],
            mask: String,
            placeholder: [String, Object],
            type: String,
            label: String,
            validatorLabel: String,
            rules: [String, Object],
            autocomplete: String,
            errorText: String,
            buttonIcon: String,
            defaultCount: [Number, String],
            maxLength: [Number, String],
            loader: Boolean,
            canSaveEmpty: Boolean,
            staticButton: Boolean,
            isFilterType: Boolean,
            hasShowLength: Boolean,
            disabled: Boolean,

            //
            surface: {
                type: String,
                default: 'tint-pale',
            },
        },

        data() {
            return {
                file: null,
            };
        },
        computed: {
            hasFull() {
                return this.file;
            },

            hasRequired() {
                if (this.rules && this.rules.indexOf('required') != -1) return true;
                return false;
            },
        },

        methods: {
            onToggle() {
                this.$refs.file.focus();
            },

            processFile(event) {
                var files = event.target.files || event.dataTransfer.files;
                if (!files.length) return;

                this.file = files[0];
                this.$emit('saveFile', this.file);
            },
        },
    };
</script>

<style lang="scss" scoped></style>
