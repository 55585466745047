var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"druk-l-support",class:{ 'druk-is-block': _vm.isBlock }},[_c('div',{staticClass:"druk-l-support__text",class:{
            'druk-is-success': _vm.isSuccess && _vm.hasNoIcon,
            'druk-is-warning': _vm.isWarning && _vm.hasNoIcon,
            'druk-is-error': _vm.isError && _vm.hasNoIcon,
            'druk-is-inverse': _vm.isInverse,
            'druk-is-truncated': _vm.isTruncated,
        },style:({ '--max-width': _vm.truncate })},[(!_vm.hasNoIcon)?_c('div',{staticClass:"druk-l-support__icon"},[_c('druk-icon',_vm._b({},'druk-icon',_vm.iconConfiguration,false))],1):_vm._e(),_vm._t("default")],2),(_vm.hasLoader)?_c('druk-loader',{attrs:{"color":'outline',"size":'xs',"isInline":true}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }